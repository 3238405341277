import { FormattedMessage } from 'react-intl';
import { ProductType } from '../api/product/types';
import type { MessageKey } from '../../configuration';

interface Props {
    productType: ProductType | undefined;
    assetBasedId: MessageKey;
    userBasedId: MessageKey;
    values?: Record<string, string | number | boolean>;
}

export function ProductTypedFormattedMessage(props: Props) {
    const { productType, assetBasedId, userBasedId, values } = props;
    if (productType === ProductType.USER) {
        return <FormattedMessage id={userBasedId} values={{ ...values }} />;
    }
    return <FormattedMessage id={assetBasedId} values={{ ...values }} />;
}
