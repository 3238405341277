import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import { extractUrlParameters } from '../urlUtils';

declare global {
    interface Window {
        dataLayer: unknown[];
    }
}

export const INTERNAL_CAMPAIGN_FALLBACK = 'buybutton-without-campaign';

export function getInternalCampaign() {
    const { internalCampaign } = extractUrlParameters(window.location.href);
    return internalCampaign || INTERNAL_CAMPAIGN_FALLBACK;
}

let lastPushedPromotionView: string | undefined;

export function gtmPromotionView() {
    const internalCampaign = getInternalCampaign();
    if (internalCampaign && lastPushedPromotionView !== internalCampaign) {
        safeDataLayerPush(() => ({
            event: 'EECpromotionImpression',
            ecommerce: {
                promoView: {
                    promotions: [{ id: internalCampaign, name: internalCampaign }],
                },
            },
        }));
        lastPushedPromotionView = internalCampaign;
    }
}

export function gtmPromotionClick() {
    const internalCampaign = getInternalCampaign();
    if (internalCampaign) {
        safeDataLayerPush(() => ({
            event: 'EECpromotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [{ id: internalCampaign, name: internalCampaign }],
                },
            },
        }));
    }
}

export function safeDataLayerPush(gtmObjectFactory: () => unknown) {
    try {
        const event = gtmObjectFactory();
        if (window.dataLayer && event) {
            window.dataLayer.push(event);
        }
    } catch (error) {
        reportErrorToSentry(error);
    }
}
