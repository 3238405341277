import messagesEN from './en-GB.json';
import messagesDE from './de-DE.json';
import messagesCS from './cs-CZ.json';
import messagesDA from './da-DK.json';
import messagesES from './es-ES.json';
import messagesFR from './fr-FR.json';
import messagesIT from './it-IT.json';
import messagesNL from './nl-NL.json';
import messagesPL from './pl-PL.json';
import messagesPT from './pt-PT.json';
import messagesPTBR from './pt-BR.json';
import messagesRO from './ro-RO.json';
import messagesLT from './lt-LT.json';
import messagesLV from './lv-LV.json';
import messagesSK from './sk-SK.json';
import messagesET from './et-EE.json';
import messagesBG from './bg-BG.json';
import messagesEL from './el-GR.json';
import messagesFI from './fi-FI.json';
import messagesHR from './hr-HR.json';
import messagesHU from './hu-HU.json';
import messagesKO from './ko-KR.json';
import messagesNB from './nb-NO.json';
import messagesSV from './sv-SE.json';
import messagesSL from './sl-SI.json';
import type { LanguageDataInterface } from '../../configuration';

export const localeData: Record<string, LanguageDataInterface> = {
    // Bulgarian
    bg: messagesBG,
    'bg-BG': messagesBG,

    // Greek
    el: messagesEL,
    'el-GR': messagesEL,

    // Finnish
    fi: messagesFI,
    'fi-FI': messagesFI,

    // Croatian
    hr: messagesHR,
    'hr-HR': messagesHR,
    'hr-BA': messagesHR,

    // English
    en: messagesEN,
    'en-GB': messagesEN,
    'en-US': messagesEN,

    // German
    de: messagesDE,
    'de-DE': messagesDE,
    'de-AT': messagesDE,
    'de-LI': messagesDE,
    'de-LU': messagesDE,
    'de-CH': messagesDE,

    // Dutch
    nl: messagesNL,
    'nl-NL': messagesNL,
    'nl-BE': messagesNL,

    // Spanish
    es: messagesES,
    'es-ES': messagesES,

    // Czech
    cs: messagesCS,
    'cs-CZ': messagesCS,

    // Danish
    da: messagesDA,
    'da-DK': messagesDA,

    // French
    fr: messagesFR,
    'fr-FR': messagesFR,
    'fr-BE': messagesFR,
    'fr-CA': messagesFR,
    'fr-LU': messagesFR,
    'fr-MC': messagesFR,
    'fr-CH': messagesFR,

    // Italian
    it: messagesIT,
    'it-IT': messagesIT,
    'it-CH': messagesIT,

    // Polish
    pl: messagesPL,
    'pl-PL': messagesPL,

    // Portuguese
    pt: messagesPT,
    'pt-PT': messagesPT,

    // Brazilian Portuguese
    'pt-BR': messagesPTBR,

    // Romanian
    ro: messagesRO,
    'ro-RO': messagesRO,

    // Lithuanian
    lt: messagesLT,
    'lt-LT': messagesLT,

    // Latvian
    lv: messagesLV,
    'lv-LV': messagesLV,

    // Estonian
    et: messagesET,
    'et-EE': messagesET,

    // Slovakian
    sk: messagesSK,
    'sk-SK': messagesSK,

    // Hungarian
    hu: messagesHU,
    'hu-HU': messagesHU,

    // Korean
    ko: messagesKO,
    'ko-KR': messagesKO,

    // Norwegian
    nb: messagesNB,
    'nb-NO': messagesNB,

    // Sweden
    sv: messagesSV,
    'se-SE': messagesSV,
    'sv-SE': messagesSV,

    // Slovenia
    sl: messagesSL,
    'sl-SI': messagesSL,
};
