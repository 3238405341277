import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getGALabelsWrapped } from './gtm/gtmLabels';
import { gtmPromotionView } from './gtm/gtmPromotions';
import { extractUrlParameters } from './urlUtils';
import { CheckoutButton } from './CheckoutButton';
import type { Product } from '../api/product/types';

interface Props {
    disabled: boolean;
    product?: Product;
}

export const BuyButtonButton = ({ disabled, product }: Props) => {
    const intl = useIntl();
    useEffect(gtmPromotionView, []);
    const { noButtonText, textOnly, notResponsive } = extractUrlParameters(window.location.href);

    const isResponsive = !notResponsive;
    const isTextOnly = !!textOnly;

    const tooltip = disabled ? intl.formatMessage({ id: 'marketplace.buybutton.notBookable' }) : undefined;

    const btnTypeClass = isTextOnly ? '' : 'btn btn-primary';
    const baseClasses = btnTypeClass + (disabled ? ' disabled' : '');
    const gaLabels = disabled ? undefined : getGALabelsWrapped();

    return (
        <div className={'buyButtonLink'} title={tooltip} {...gaLabels?.view}>
            {isResponsive && (
                <CheckoutButton
                    product={product}
                    className={`${baseClasses} btn-icon-only visible-xs`}
                    disabled={disabled}
                    noText={true}
                    textOnly={isTextOnly}
                />
            )}
            <CheckoutButton
                product={product}
                className={`${baseClasses}${isResponsive && !isTextOnly ? ' hidden-xs' : ''}${
                    noButtonText ? ' btn-icon-only' : ''
                }`}
                disabled={disabled}
                noText={!!noButtonText}
                textOnly={isTextOnly}
            />
        </div>
    );
};
