import { getInternalCampaign } from './gtmPromotions';
import { extractUrlParameters } from '../urlUtils';

export interface GoogleAnalyticsLabels {
    'data-track-ga-event-trigger': string;
    'data-track-ga-event-category': string;
    'data-track-ga-event-action': string;
    'data-track-ga-event-label'?: string;
    'data-track-ga-custom-dimension-account-id'?: string;
    'data-track-ga-custom-dimension-internal-campaign'?: string;
}

function getAssetIdsString(assetIds?: string[]) {
    return assetIds ? `[${assetIds.join(', ')}]` : '[]';
}

export function getGALabelsWrapped() {
    const { productId, assetIds } = extractUrlParameters(window.location.href);
    if (!productId) {
        return null;
    }
    const assetIdsArray = assetIds ? assetIds.split(',') : undefined;
    return getGALabels(productId, assetIdsArray);
}

export function getGALabels(productId: string, assetIds?: string[], accountId?: string) {
    return {
        view: getGaLabels('visibility', 'buyButtonShown', productId, assetIds, accountId),
        click: getGaLabels('click', 'buyButtonClicked', productId, assetIds, accountId),
    };
}

function getGaLabels(
    eventTrigger: string,
    eventAction: string,
    productId: string,
    assetIds?: string[],
    accountId?: string
): GoogleAnalyticsLabels {
    const parentUrl = document.referrer || 'unknown';

    const internalCampaign = getInternalCampaign();

    const eventLabel = `productId::${productId}, assetIds::${getAssetIdsString(assetIds)}, parentUrl::${parentUrl}`;

    return {
        'data-track-ga-event-trigger': eventTrigger,
        'data-track-ga-event-category': 'marketplace',
        'data-track-ga-event-action': eventAction,
        'data-track-ga-event-label': eventLabel,
        ...(accountId && { 'data-track-ga-custom-dimension-account-id': accountId }),
        ...(internalCampaign && { 'data-track-ga-custom-dimension-internal-campaign': internalCampaign }),
    };
}
