import type React from 'react';
import { getCheckoutWindowProps } from './popupService';
import { getWindowScreen } from './screenWrapper';
import { gtmPromotionClick } from './gtm/gtmPromotions';
import { createCheckoutUrl, extractUrlParameters } from './urlUtils';
import { getGALabelsWrapped } from './gtm/gtmLabels';
import { FormattedMessage } from 'react-intl';
import type { Product } from '../api/product/types';

const createRedirectToCheckout = (windowId: string, checkoutUrl: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    const { windowName, sizeString } = getCheckoutWindowProps(getWindowScreen(), windowId);
    gtmPromotionClick();
    window.open(checkoutUrl + (sizeString ? '&popup=true' : ''), windowName, sizeString);
};

interface Props {
    product?: Product;
    className: string;
    disabled: boolean | undefined;
    noText: boolean;
    textOnly: boolean;
}

export function CheckoutButton({ className, noText, textOnly, disabled, product }: Props) {
    const { productId, assetIds, level, resourceIds } = extractUrlParameters(window.location.href);
    if (!productId) {
        return null;
    }

    const overwrittenLevel = level ?? product?.level;
    const windowId = productId + (overwrittenLevel ?? '');
    const checkoutUrl = createCheckoutUrl(productId, assetIds, overwrittenLevel, resourceIds);
    const redirectToCheckout = disabled ? undefined : createRedirectToCheckout(windowId, checkoutUrl);
    const gaLabels = disabled ? undefined : getGALabelsWrapped();

    return (
        <a className={className} href={checkoutUrl} onClick={redirectToCheckout} {...gaLabels?.click}>
            {!textOnly && <span className='rioglyph rioglyph-shopping-cart' />}
            {noText ? null : (
                <span>
                    <FormattedMessage id={'marketplace.buybutton.bookNow'} />
                </span>
            )}
        </a>
    );
}
