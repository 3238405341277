import type { Decoder } from 'io-ts';
import { isRight } from 'fp-ts/es6/Either';
import { PathReporter } from 'io-ts/es6/PathReporter';
import { reportErrorToSentry } from '../../configuration/setup/sentry';
import { FetchError } from './FetchError';

export function onRejected(error: Error) {
    if (![404, 401, 400, 200, undefined].includes((error as FetchError).statusCode)) {
        reportErrorToSentry(error);
    } else {
        console.debug(error);
    }
    return Promise.reject(error);
}

export function jsonOrReject(response: Response) {
    if (response.ok) {
        return response.json().catch(error => {
            throw new FetchError({ ...response, statusText: error.message });
        });
    }
    return Promise.reject(new FetchError(response));
}

export function decodeJson<T>(decoder: Decoder<unknown, T>) {
    return (json: unknown): T => {
        const validationResult = decoder.decode(json);

        if (isRight(validationResult)) {
            return validationResult.right;
        }
        throw new Error(`Error during parsing response: ${PathReporter.report(validationResult)} json: ${json}`);
    };
}
