import type { ConfigState } from './types';

export const config: ConfigState = {
    backend: {
        MARKETPLACE_SERVICE: import.meta.env.VITE_MARKETPLACE_SERVICE,
    },
    id: import.meta.env.VITE_ID,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    serviceEnvironment: import.meta.env.MODE,
};
