import { FormattedMessage } from 'react-intl';
import { extractUrlParameters } from './urlUtils';
import type { ProductType } from '../api/product/types';
import { ProductTypedFormattedMessage } from './ProductTypedFormattedMessage';

interface Props {
    showPrice: boolean;
    dayPrice: string | undefined;
    monthPrice: string | undefined;
    productType: ProductType;
    productId: string | undefined;
}

export const BuyButtonPriceLabel = (props: Props) => {
    const { showPrice, dayPrice, monthPrice, productType, productId } = props;
    const { noPrice, notResponsive, buttonLeft } = extractUrlParameters(window.location.href);

    if (noPrice) {
        return <>{!notResponsive && <div className={'buyButtonPrice'} />}</>;
    }

    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let content;
    if (!showPrice) {
        content = null;
    } else if (!dayPrice) {
        content = (
            <div className='text-size-14 line-height-20 text-medium label label-success label-filled'>
                <FormattedMessage id={'marketplace.buybutton.free'} />
            </div>
        );
    } else {
        if (productId?.toLowerCase().startsWith('latam-')) {
            content = (
                <>
                    <div className='text-size-12 line-height-12'>
                        <ProductTypedFormattedMessage
                            productType={productType}
                            userBasedId='marketplace.buybutton.price.latam.user.month'
                            assetBasedId='marketplace.buybutton.price.latam.vehicle.month'
                        />
                    </div>
                    <span className='text-size-20 line-height-20 text-medium'>{monthPrice}</span>
                </>
            );
        } else {
            content = (
                <>
                    <div className='text-size-12 line-height-12'>
                        <ProductTypedFormattedMessage
                            productType={productType}
                            userBasedId='marketplace.buybutton.price.rate.user'
                            assetBasedId='marketplace.buybutton.price.rate.asset'
                        />
                    </div>
                    <div className='display-flex align-items-end flex-wrap height-20'>
                        <span className='text-size-20 line-height-20 text-medium'>{dayPrice}</span>
                        {monthPrice && (
                            <span className='text-size-12 margin-left-3'>
                                {` / ${monthPrice} `}
                                <FormattedMessage id={'marketplace.buybutton.price.rate.month'} />
                            </span>
                        )}
                    </div>
                </>
            );
        }
    }
    return (
        <div className={`buyButtonPrice ${buttonLeft ? 'display-flex flex-column align-items-end' : ''}`}>
            {content}
        </div>
    );
};
