import type { Product } from '../api/product/types';
import { fetchProductDetails } from '../api/product/productCalls';
import { useEffect, useState } from 'react';
import type { FetchError } from '../api/FetchError';

export enum ResponseStatus {
    loading = 'loading',
    success = 'success',
    not_found = 'not_found',
    error = 'error',
    missing_level = 'missing_level',
}

export interface UseProductDetailsResponse {
    product: Product | undefined;
    status: ResponseStatus | undefined;
}

export function useProductDetails({
    productId,
    level,
}: {
    productId: string | undefined;
    level?: string | undefined;
}): UseProductDetailsResponse {
    const [product, setProduct] = useState<Product | undefined>();
    const [status, setStatus] = useState<ResponseStatus | undefined>();

    useEffect(() => {
        if (productId) {
            setStatus(ResponseStatus.loading);
            fetchProductDetails(productId, level)
                .then(fetchedProduct => {
                    setProduct(fetchedProduct);
                    setStatus(ResponseStatus.success);
                })
                .catch((error: FetchError) => {
                    if (error.statusCode === 404) {
                        setStatus(ResponseStatus.not_found);
                    } else if (error.statusCode === 400) {
                        setStatus(ResponseStatus.missing_level);
                    } else {
                        setStatus(ResponseStatus.error);
                    }
                });
        }
    }, [productId, setProduct, setStatus, level]);

    return { product, status };
}
