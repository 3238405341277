import * as Sentry from '@sentry/browser';
import { config } from '../../config';

if (import.meta.env.PROD) {
    const release = APP_VERSION;
    const environment = config.serviceEnvironment;

    // should have been called before using it here
    // ideally before even rendering your react app
    Sentry.init({
        dsn: config.sentryToken,
        ignoreErrors: [
            'TypeError: Failed to fetch',
            'TypeError: NetworkError when attempting to fetch resource.',
            "Can't find variable: Intl", // Safari 9 and Safari 9 Mobile, all others have this capability
            'Cannot find function supportedLocalesOf in object function NumberFormat',
            'Non-Error promise rejection captured',
        ],
        environment,
        release,
    });
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const reportErrorToSentry = (...args: any[]) => {
    if (import.meta.env.PROD && !document.referrer.includes('localhost')) {
        // @ts-ignore
        Sentry.captureException(...args);
    } else {
        console.error(...args);
    }
};
