import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';
import mapValues from 'lodash/mapValues';
import { localeData } from '../../features/translations/localeData';

const DEFAULT_LOCALE = 'en-GB';
const supportedLocaleMap = mapValues(localeData, (value, key) => key);

const extractLanguage = flow(defaultTo(DEFAULT_LOCALE), split('-'), head);

const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

export { DEFAULT_LANG, DEFAULT_LOCALE, extractLanguage, supportedLocaleMap };
