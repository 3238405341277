import { getDayAndMonthPrice } from './priceUtils';
import { BuyButtonButton } from './BuyButton';
import { BuyButtonPriceLabel } from './BuyButtonPriceLabel';
import { extractUrlParameters } from './urlUtils';
import { type Product, ProductType } from '../api/product/types';

interface Props {
    userLocale: string;
    showPrice: boolean;
    disableButton: boolean;
    showButton: boolean;
    product?: Product;
}

export const BuyButtonContent = (props: Props) => {
    const { product, userLocale, showPrice, disableButton, showButton } = props;
    const { buttonLeft } = extractUrlParameters(window.location.href);
    const price = product?.price;
    const { dayPrice, monthPrice } = getDayAndMonthPrice(price, userLocale, product?.currency);
    const productType = product?.productType;
    const productId = product?.productId;

    return (
        <div className={`buyButton${buttonLeft ? ' flex-row-reverse' : ''}`}>
            <BuyButtonPriceLabel
                showPrice={showPrice}
                dayPrice={dayPrice}
                monthPrice={monthPrice}
                productType={productType ?? ProductType.ASSET}
                productId={productId ?? undefined}
            />
            {showButton && <BuyButtonButton disabled={disableButton} product={product} />}
        </div>
    );
};
