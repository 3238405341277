import cloneDeep from 'lodash/fp/cloneDeep';
import flow from 'lodash/fp/flow';
import forEach from 'lodash/fp/forEach';
import has from 'lodash/fp/has';
import keys from 'lodash/fp/keys';

import { DEFAULT_LOCALE, extractLanguage, supportedLocaleMap } from './lang';
import messagesEN from '../../features/translations/en-GB.json';
import { getDisplayLocale } from './DisplayLocaleService';
import { localeData } from '../../features/translations/localeData';

const getSupportedLocaleFromData = data =>
    flow(
        locale => (has(locale, data) ? locale : extractLanguage(locale)),
        locale => (has(locale, data) ? locale : DEFAULT_LOCALE)
    );

const getMessages = ({ allMessages }, locale) => allMessages[supportedLocaleMap[locale]] || allMessages[DEFAULT_LOCALE];

const isLangOnly = locale => !/-/.test(locale);

const defaultFor = locale => supportedLocaleMap[locale];

const patchMissingMessages = (knownMessages, messages) => {
    const result = cloneDeep(messages);

    flow(
        keys,
        forEach(key => {
            if (!has(key, result)) {
                result[key] = knownMessages[key];
            }
        })
    )(knownMessages);

    return result;
};

const applyLocale = (state, preferredLocale) => {
    const { allMessages } = state;

    const getDisplayLocale = getSupportedLocaleFromData(allMessages);
    const getSupported = getSupportedLocaleFromData(supportedLocaleMap);

    const displayLocale = getDisplayLocale(preferredLocale);

    const closest = getSupported(preferredLocale);
    const supportedLocale = isLangOnly(closest) ? defaultFor(closest) : closest;

    const canFetchSupportedLocale = displayLocale !== supportedLocale;

    const displayMessages = patchMissingMessages(getMessages(state, DEFAULT_LOCALE), getMessages(state, displayLocale));

    return {
        allMessages,
        canFetchSupportedLocale,
        displayLocale,
        displayMessages,
        preferredLocale,
        supportedLocale,
    };
};

export const createDefaultState = () => {
    const localeOfUnknownUser = getDisplayLocale();

    const defaultMessages = {
        [DEFAULT_LOCALE]: messagesEN,
    };

    const allMessages = {
        ...defaultMessages,
        ...mergeLanguageData(defaultMessages, localeData[localeOfUnknownUser], localeOfUnknownUser),
    };
    return applyLocale({ allMessages }, localeOfUnknownUser);
};

const isDefaultLocaleForLang = locale => supportedLocaleMap[locale] === locale;

const hasLocale = locale => Boolean(supportedLocaleMap[locale]);

const mergeLanguageData = (allMessages, languageData, locale) => {
    const baseLang = extractLanguage(locale);
    const messages = {
        [locale]: languageData,
    };

    if (isDefaultLocaleForLang(locale) || !hasLocale(baseLang)) {
        messages[baseLang] = languageData;
    }

    return {
        ...allMessages,
        ...messages,
    };
};

export default function langReducer(state = createDefaultState()) {
    return state;
}
